var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"content"},[_c('div',{staticStyle:{"background":"#fff"}},[_c('div',{staticClass:"domrelative wrapper_1200"},[_vm._m(2),_c('div',{staticClass:"domUn domFexidTop",style:(`height:` + _vm.maxHigth + `px`)},[_vm._m(3),_c('img',{attrs:{"src":"https://oss.pinmallzj.com/image/maintain/2023/09/12/聚如切图01-nfdhlf9xhkvc.png","alt":""}})])])]),_c('div',[_c('div',{staticClass:"domrelative wrapper_1200"},[_vm._m(4),_c('div',{staticClass:"domUn domFexidTop",style:(`height:` + _vm.maxHigth + `px`)},[_vm._m(5),_c('img',{attrs:{"src":"https://oss.pinmallzj.com/image/maintain/2023/09/12/聚如切图02-nopd8q7dxv1d.png","alt":""}})])])]),_c('div',{staticStyle:{"background":"#fff"}},[_c('div',{staticClass:"domrelative wrapper_1200"},[_vm._m(6),_c('div',{staticClass:"domUn domFexidTop",style:(`height:` + _vm.maxHigth + `px`)},[_vm._m(7),_c('img',{attrs:{"src":"https://oss.pinmallzj.com/image/maintain/2023/09/12/聚如切图03-iffd3xqmkhq5.png","alt":""}})])])]),_c('div',[_c('div',{staticClass:"domrelative wrapper_1200"},[_vm._m(8),_c('div',{staticClass:"domUn domFexidTop",style:(`height:` + _vm.maxHigth + `px`)},[_vm._m(9),_c('img',{attrs:{"src":"https://oss.pinmallzj.com/image/maintain/2023/09/12/聚如切图04-uxxqsavr67sh.png","alt":""}})])])]),_c('div',{staticStyle:{"background":"#eeeef3"}},[_c('div',{staticClass:"domrelative wrapper_1200"},[_vm._m(10),_c('div',{staticClass:"domUn domFexidTop",style:(`height:` + _vm.maxHigth + `px`)},[_vm._m(11),_c('img',{attrs:{"src":"https://oss.pinmallzj.com/image/maintain/2023/09/12/聚如切图05-hp8iewvhegk2.png","alt":""}})])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"topImg"},[_c('img',{attrs:{"src":"https://oss.pinmallzj.com/image/maintain/2023/09/01/聚如-dktp06gohcs7.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"background":"#fff"}},[_c('div',{staticClass:"wrapper_1200 dom1"},[_c('p',{staticClass:"title"},[_vm._v("聚如SaaS")]),_c('p',{staticClass:"titleText"},[_c('span',[_vm._v("该平台围绕礼品服务商业务场景：通过数字化平台赋能礼品服务商，使其完成线上采购，线上经营的数字化转型升级，助力礼品服务商在降本增效的同时树立更专业的行业形象。")]),_c('span',{staticClass:"icon1"},[_c('img',{attrs:{"src":"https://oss.pinmallzj.com/image/maintain/2023/09/01/Vector-821c0ssjruuc.png","alt":""}})]),_c('span',{staticClass:"icon2"},[_c('img',{attrs:{"src":"https://oss.pinmallzj.com/image/maintain/2023/09/01/Vectorxx1x-4dstvlpteupa.png","alt":""}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"domup"},[_c('img',{staticStyle:{"width":"100%","height":"2050px"},attrs:{"src":"https://oss.pinmallzj.com/image/maintain/2023/09/08/41281694153461_xpic-x64zn5yq0dr6.jpg","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("核心功能一")]),_c('span')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"domup"},[_c('img',{staticStyle:{"width":"100%","height":"2050px"},attrs:{"src":"https://oss.pinmallzj.com/image/maintain/2023/09/08/41281694153461_xpic-x64zn5yq0dr6.jpg","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("核心功能二")]),_c('span')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"domup"},[_c('img',{staticStyle:{"width":"100%","height":"2050px"},attrs:{"src":"https://oss.pinmallzj.com/image/maintain/2023/09/08/41281694153461_xpic-x64zn5yq0dr6.jpg","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("核心功能三")]),_c('span')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"domup"},[_c('img',{staticStyle:{"width":"100%","height":"2050px"},attrs:{"src":"https://oss.pinmallzj.com/image/maintain/2023/09/08/41281694153461_xpic-x64zn5yq0dr6.jpg","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("核心功能四")]),_c('span')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"domup"},[_c('img',{staticStyle:{"width":"100%","height":"2050px"},attrs:{"src":"https://oss.pinmallzj.com/image/maintain/2023/09/08/41281694153461_xpic-x64zn5yq0dr6.jpg","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("核心功能五")]),_c('span')])
}]

export { render, staticRenderFns }