<template>
  <div class="bg">
    <div class="topImg">
      <img
        src="https://oss.pinmallzj.com/image/maintain/2023/09/01/聚如-dktp06gohcs7.png"
        alt=""
      />
    </div>
    <div style="background: #fff">
      <div class="wrapper_1200 dom1">
        <p class="title">聚如SaaS</p>
        <p class="titleText">
          <span
            >该平台围绕礼品服务商业务场景：通过数字化平台赋能礼品服务商，使其完成线上采购，线上经营的数字化转型升级，助力礼品服务商在降本增效的同时树立更专业的行业形象。</span
          >
          <span class="icon1">
            <img
              src="https://oss.pinmallzj.com/image/maintain/2023/09/01/Vector-821c0ssjruuc.png"
              alt=""
            />
          </span>
          <span class="icon2">
            <img
              src="https://oss.pinmallzj.com/image/maintain/2023/09/01/Vectorxx1x-4dstvlpteupa.png"
              alt=""
            />
          </span>
        </p>
      </div>
    </div>
    <div class="content">
      <div style="background: #fff">
        <div class="domrelative wrapper_1200">
          <div class="domup">
            <img
              src="https://oss.pinmallzj.com/image/maintain/2023/09/08/41281694153461_xpic-x64zn5yq0dr6.jpg"
              alt=""
              style="width: 100%; height: 2050px"
            />
          </div>
          <div class="domUn domFexidTop" :style="`height:` + maxHigth + `px`">
            <p>
              <span>核心功能一</span>
              <span></span>
            </p>
            <img
              src="https://oss.pinmallzj.com/image/maintain/2023/09/12/聚如切图01-nfdhlf9xhkvc.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <div>
        <div class="domrelative wrapper_1200">
          <div class="domup">
            <img
              src="https://oss.pinmallzj.com/image/maintain/2023/09/08/41281694153461_xpic-x64zn5yq0dr6.jpg"
              alt=""
              style="width: 100%; height: 2050px"
            />
          </div>

          <div class="domUn domFexidTop" :style="`height:` + maxHigth + `px`">
            <p>
              <span>核心功能二</span>
              <span></span>
            </p>
            <img
              src="https://oss.pinmallzj.com/image/maintain/2023/09/12/聚如切图02-nopd8q7dxv1d.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <div style="background: #fff">
        <div class="domrelative wrapper_1200">
          <div class="domup">
            <img
              src="https://oss.pinmallzj.com/image/maintain/2023/09/08/41281694153461_xpic-x64zn5yq0dr6.jpg"
              alt=""
              style="width: 100%; height: 2050px"
            />
          </div>
          <div class="domUn domFexidTop" :style="`height:` + maxHigth + `px`">
            <p>
              <span>核心功能三</span>
              <span></span>
            </p>
            <img
              src="https://oss.pinmallzj.com/image/maintain/2023/09/12/聚如切图03-iffd3xqmkhq5.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <div>
        <div class="domrelative wrapper_1200">
          <div class="domup">
            <img
              src="https://oss.pinmallzj.com/image/maintain/2023/09/08/41281694153461_xpic-x64zn5yq0dr6.jpg"
              alt=""
              style="width: 100%; height: 2050px"
            />
          </div>
          <div class="domUn domFexidTop" :style="`height:` + maxHigth + `px`">
            <p>
              <span>核心功能四</span>
              <span></span>
            </p>
            <img
              src="https://oss.pinmallzj.com/image/maintain/2023/09/12/聚如切图04-uxxqsavr67sh.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <div style="background: #eeeef3">
        <div class="domrelative wrapper_1200">
          <div class="domup">
            <img
              src="https://oss.pinmallzj.com/image/maintain/2023/09/08/41281694153461_xpic-x64zn5yq0dr6.jpg"
              alt=""
              style="width: 100%; height: 2050px"
            />
          </div>
          <div class="domUn domFexidTop" :style="`height:` + maxHigth + `px`">
            <p>
              <span>核心功能五</span>
              <span></span>
            </p>
            <img
              src="https://oss.pinmallzj.com/image/maintain/2023/09/12/聚如切图05-hp8iewvhegk2.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      maxHigth: 0,
    };
  },
  mounted() {
    this.maxHigth = window.screen.availHeight;
    let header = document.querySelector(".header");
    header.style.position = "static";
  },
  methods: {},
  beforeDestroy() {
    let header = document.querySelector(".header");
    header.style.position = "fixed";
  },
};
</script>
<style lang="scss" scoped>
.bg {
  scroll-behavior: smooth;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 2;
  .topImg {
    img {
      width: 100%;
      min-height: 500px;
    }
  }
  .dom1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 60px 0 80px 0;
    .title {
      color: #333a45;
      font-size: 34px;
      font-weight: 500;
      margin-bottom: 28px;
    }
    .titleText {
      position: relative;
      padding: 0 200px 0 200px;
      color: #485261;
      font-size: 18px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 4px;
      .icon1 {
        position: absolute;
        top: -40px;
        left: 130px;
        width: 39px;
        height: 34px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .icon2 {
        position: absolute;
        bottom: -40px;
        right: 130px;
        width: 39px;
        height: 34px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .content {
    width: 100%;
    background: #eeeef3;
    display: flex;
    flex-direction: column;
    .domrelative {
      position: relative;
      height: 2000px;
      padding-top: 60px;
      .domup {
        position: absolute;
        top: 0;
        z-index: 2;
      }
      .domUn {
        padding: 30px 0 80px 0;
        position: absolute;
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img {
          width: 100%;
          margin-top: 60px;
        }
        p {
          display: flex;
          flex-direction: column;
          align-items: center;
          span {
            &:nth-child(1) {
              color: #485261;
              text-align: center;
              font-size: 28px;
              font-weight: 500;
              margin-bottom: 15px;
            }
            &:nth-child(2) {
              display: inline-block;
              width: 40px;
              height: 6px;
              background: #288cff;
            }
          }
        }
      }
    }
  }
}

.domFexidTop {
  position: sticky !important;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
</style>
